import { render, staticRenderFns } from "./walmart.vue?vue&type=template&id=40c03660&scoped=true"
import script from "./walmart.vue?vue&type=script&lang=js"
export * from "./walmart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40c03660",
  null
  
)

export default component.exports