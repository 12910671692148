<template>
<div v-if="tabs !== null" class="text-sm font-medium text-center text-gray-500 border-b border-gray-50 dark:text-gray-400 dark:border-gray-700">
    <ul class="flex flex-wrap -mb-px">
        <li v-for="(tab, index) in tabs" :key="index + 't'" class="mr-2 cursor-pointer" @click="tabsSet(index)">
            <!-- <span href="#" :class="`${ tab.active ? 'text-primary  border-primary' : 'border-transparent'} border-b-2  px-2 py-2 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 flex`" aria-current="page">{{tab.name}} <span v-if="loader && tab.active" class="loader2 ease-linear ml-1"></span></span> -->
            <span href="#" :class="`${ tab.active ? 'text-primary  border-primary' : 'border-transparent'} border-b-2  px-4 py-2 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 flex`" aria-current="page">{{tab.name}} <span  v-if="loader && tab.active" class="loader2 ease-linear ml-1"></span></span>
        </li>
    </ul>
</div>
</template>
<script>
export default {
  props: ['tabsList', 'loader'],
  data () {
    return {
        tabs: null
    }
  },
  mounted () {
    this.tabs = this.tabsList
  },
  watch: {
  },
  methods: {
    tabsSet (index) {
        this.$emit('setNewTabActive', index)
        // this.$root.$emit('setNewTabActive', index)
    },
  }
}
</script>
<style scoped>
.loader2 {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #317ED2;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1.2s linear infinite;
}
</style>
